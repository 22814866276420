import axios from 'axios';

const api = axios.create({
    baseURL: 'https://api.seen-delivery.com/api/v1/user/'
});

// api.interceptors.request.use(
//     (config) => {
//         const store = useApp.getState();
//         if (store.userToken) {
//             config.headers.Authorization = `Bearer ${store.userToken}`;
//         }
//         return config;
//     },
//     (error) => Promise.reject(error)
// );

// api.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const originalRequest = error.config;

//         // Token expired/invalid.
//         if (error.response?.status === 401 && !originalRequest._retry) {
//             // originalRequest._retry = true;

//             // const store = useApp.getState();
//             // store.logout();

//             // const navigate = useNavigate();
//             // navigate('/login');
//         }

//         return Promise.reject(error);
//     }
// );

export default api;