import { List } from '@phosphor-icons/react';

const Header = (props) => {
  return (
    <header className="sticky top-0 z-[1] flex w-full bg-light-darker-bg dark:bg-dark-darker-bg dark:bg-dark-darker-bg lg:hidden">
      <div className="flex items-center gap-2 px-6 py-4 sm:gap-4">
        <button
          aria-controls="sidebar"
          onClick={(e) => {
            e.stopPropagation();
            props.setSidebarOpen(!props.sidebarOpen);
          }}
          className="flex items-center justify-center rounded-sm p-1.5 shadow-sm lg:hidden"
        >
          <List className="size-6 text-light-white-text dark:text-dark-white-text" />
        </button>

        {/* <Link to="/dashboard" className="text-2xl lg:text-3xl">
          <span className="text-white">Seen</span> <span className="text-primary">Delivery</span>
        </Link> */}
      </div>
    </header>
  );
};

export default Header;