import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import './css/App.css';
import LandingPage from './pages/Landing';
import LoginPage from './pages/Auth/LoginPage';
import SignupPage from './pages/Auth/SignupPage';
import DashboardHomePage from './pages/Dashboard/Home';
import DashboardOrdersPage from './pages/Dashboard/Orders';
import { useEffect, useState } from 'react';
import DashboardMenuPage from './pages/Dashboard/Menu/Index';
import ItemFormPage from './pages/Dashboard/Menu/ItemForm';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import RestaurantFormPage from './pages/Dashboard/RestaurantForm';
import BranchCreatePage from './pages/Dashboard/BranchCreate';
import CategoryFormPage from './pages/Dashboard/Menu/CategoryForm';
import PostFormPage from './pages/Dashboard/Post/PostForm';
import DashboardPostsPage from './pages/Dashboard/Post/Index';
import DeleteAccountPage from './pages/DeleteAccount';
import { AppProvider, useApp } from './contexts/AppContext';
import DownloadPage from './pages/Download';

const AuthRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { userToken } = useApp();

  // useEffect(() => {
  //   const loginFromLocalStorage = async () => {
  //     // TODO: Clean this up.
  //     try {
  //       const localToken = localStorage.getItem('token');

  //       if (!store.user && localToken) {
  //         const response = await api.get('', {
  //           headers: {
  //             Authorization: `Bearer ${localToken}`
  //           }
  //         });

  //         if (response.data.success) {
  //           store.logIn(response.data.user, localToken);
  //         } else {
  //           localStorage.removeItem('token');
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       localStorage.removeItem('token');
  //     }
  //   };

  //   const fetchRestaurant = async () => {
  //     const response = await api.get('restaurants');

  //     const localRestaurantId = localStorage.getItem('restaurant_id');
  //     const localBranchId = localStorage.getItem('branch_id');

  //     if (response.data.success) {
  //       const newRestaurant = response.data.restaurants.find((r) => r.id === parseInt(localRestaurantId)) || response.data.restaurants[0];

  //       console.log('Hi');

  //       store.setSelectedRestaurant(newRestaurant);

  //       if (newRestaurant.branches.length > 0) {
  //         const newBranch = newRestaurant.branches.find((b) => b.id === parseInt(localBranchId)) || newRestaurant.branches[0];

  //         store.setSelectedBranch(newBranch);
  //       }
  //     } else {
  //       localStorage.removeItem('restaurant_id');
  //       localStorage.removeItem('branch_id');
  //     }
  //   };

  //   console.log('Hello');

  //   loginFromLocalStorage().then(fetchRestaurant).finally(() => setLoading(false));

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (!userToken) return;

    setLoading(false);
  }, [userToken]);

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center bg-light-darker-bg dark:bg-dark-darker-bg">
        <div className="size-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
      </div>
    );
  }

  if (!userToken) {
    return <Navigate to="/login" replace />;
  }

  return children ? children : <Outlet />;
};

const GuestRoute = ({ children }) => {
  if (localStorage.getItem('token')) {
    return <Navigate to="/dashboard" replace />;
  }

  return children ? children : <Outlet />;
};

function App() {
  document.body.classList.add('dark');

  const getSubdomain = () => {
    const host = window.location.host;
    const subdomain = host.split('.')[0]; // This assumes the subdomain is the first part of the host
    return subdomain;
  };

  // Determine the current subdomain
  const subdomain = getSubdomain();

  // Define routes for the main domain
  const mainDomainRoutes = (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/privacy" element={<PrivacyPolicyPage />} />
      <Route path="/terms" element={<PrivacyPolicyPage />} />
      <Route path="/about" element={<PrivacyPolicyPage />} />
      <Route path="/delete-account" element={<DeleteAccountPage />} />

      <Route element={<GuestRoute />}>
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/login" element={<LoginPage />} />
      </Route>

      <Route element={<AuthRoute />}>
        <Route path="/dashboard" element={<DashboardHomePage />} />
        <Route path="/orders" element={<DashboardOrdersPage />} />
        <Route path="/menu" element={<DashboardMenuPage />} />
        <Route path="/items/create" element={<ItemFormPage />} />
        <Route path="/items/edit/:id" element={<ItemFormPage />} />
        <Route path="/posts" element={<DashboardPostsPage />} />
        <Route path="/posts/create" element={<PostFormPage />} />
        <Route path="/categories/create" element={<CategoryFormPage />} />
        <Route path="/categories/edit/:id" element={<CategoryFormPage />} />
        <Route path="/restaurant/create" element={<RestaurantFormPage isEdit={false} />} />
        <Route path="/restaurant/edit/" element={<RestaurantFormPage isEdit={true} />} />
        <Route path="/restaurant/branch/create" element={<BranchCreatePage />} />
      </Route>
    </Routes>
  );

  // Define routes for 'download'
  const downloadSubdomainRoutes = (
    <Routes>
      <Route path="/" element={<DownloadPage />} />
    </Routes>
  );

  return (
    <AppProvider>
      {subdomain === 'download' ? downloadSubdomainRoutes : mainDomainRoutes}
    </AppProvider>
  )

  // return (
  //   <AppProvider>
  //     <Routes>
  //       <Route path="/" element={<LandingPage />} />
  //       <Route path="/privacy" element={<PrivacyPolicyPage />} />
  //       <Route path="/terms" element={<PrivacyPolicyPage />} />
  //       <Route path="/about" element={<PrivacyPolicyPage />} />
  //       <Route path="/download" element={<DownloadPage />} />

  //       <Route path="/delete-account" element={<DeleteAccountPage />} />

  //       <Route element={<GuestRoute />}>
  //         <Route path="/signup" element={<SignupPage />} />
  //         <Route path="/login" element={<LoginPage />} />
  //       </Route>

  //       <Route element={<AuthRoute />}>
  //         <Route path="/dashboard" element={<DashboardHomePage />} />

  //         <Route path="/orders" element={<DashboardOrdersPage />} />

  //         <Route path="/menu" element={<DashboardMenuPage />} />
  //         <Route path="/items/create" element={<ItemFormPage />} />
  //         <Route path="/items/edit/:id" element={<ItemFormPage />} />

  //         <Route path="/posts" element={<DashboardPostsPage />} />
  //         <Route path="/posts/create" element={<PostFormPage />} />

  //         <Route path="/categories/create" element={<CategoryFormPage />} />
  //         <Route path="/categories/edit/:id" element={<CategoryFormPage />} />

  //         <Route path="/restaurant/create" element={<RestaurantFormPage isEdit={false} />} />
  //         <Route path="/restaurant/edit/" element={<RestaurantFormPage isEdit={true} />} />
  //         <Route path="/restaurant/branch/create" element={<BranchCreatePage />} />
  //       </Route>
  //     </Routes>
  //   </AppProvider>
  // );
}

export default App;
