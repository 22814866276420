import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import { Link } from 'react-router-dom';
import api from '../../../services/apiService';
import { Plus, Trash } from '@phosphor-icons/react';
import { useApp } from '../../../contexts/AppContext';

const DashboardMenuPage = () => {
    const [items, setItems] = useState([]);
    const { selectedRestaurant, selectedBranch } = useApp();

    useEffect(() => {
        if (!selectedRestaurant) return;

        const fetchItems = async () => {
            const response = await api.get(`restaurants/${selectedRestaurant.id}/items`);

            if (response.data.success) {
                setItems(response.data.items);
            }
            else {
                console.error(response.data.message);
            }
        };

        fetchItems();
    }, [selectedRestaurant, selectedBranch]);

    const deleteItem = async (id) => {
        try {
            const response = await api.delete(`restaurants/${selectedRestaurant.id}/items/${id}`);

            if (response.data.success) {
                setItems(items.filter((item) => item.id !== id));
            }
            else {
                console.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <DefaultLayout>
            <div className="container mx-auto p-4">
                <div className="flex justify-between">
                    <h1 className="text-2xl font-bold mb-4">Items</h1>
                    <div className="inline-flex">
                        <Link to="/categories/create" className="inline-flex min-w-48 items-center justify-center gap-2 rounded-lg bg-primary text-sm text-white shadow-md mr-4">
                            <Plus size={20} />
                            Create Category
                        </Link>

                        <Link to="/items/create" className="inline-flex min-w-32 items-center justify-center gap-2 rounded-lg bg-primary text-sm text-white shadow-md">
                            <Plus size={20} />
                            Create Item
                        </Link>
                    </div>
                </div>

                {/* Show Item List */}
                <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {items.map((item) => (
                        <div className="bg-light-dark-bg dark:bg-dark-dark-bg p-4 rounded-lg shadow-lg relative">
                            <Trash className="absolute top-2 right-2 cursor-pointer" size={20} onClick={() => deleteItem(item.id)} color='red' />

                            <Link to={`/items/edit/${item.id}`} key={item.id}>
                                <div className="flex">
                                    <img src={item.image.url} alt={item.name} className="w-24 h-24 object-cover rounded-lg" />
                                    <div className="ml-4">
                                        <h2 className="text-lg font-semibold">{item.name}</h2>
                                        <p className="text-light-gray-text dark:text-dark-gray-text">{item.description}</p>
                                        <p className="text-light-gray-text dark:text-dark-gray-text">Price: {item.base_price.toFixed(2)}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </DefaultLayout>
    );
};

export default DashboardMenuPage;