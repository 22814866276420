import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import appImage from '../assets/images/app.png';
import { FormProvider, useForm } from 'react-hook-form';
import FormInput from '../components/Form/FormInput';
import FormTextarea from '../components/Form/FormTextarea';
// import appImage from './path-to-your-app-image'; // replace with the path to your image
// import googlePlayBadge from './path-to-google-play-badge'; // replace with the path to Google Play badge
// import appStoreBadge from './path-to-app-store-badge'; // replace with the path to App Store badge
import appGalleryBadge from '../assets/images/huawei.png'

const appStoreBadge = 'https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg';

const googlePlayBadge = 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png';

const LandingPage = () => {
    const form = useForm();

    const onSubmit = (data) => {
        console.log(data);
    };

    useEffect(() => {
        document.body.classList.remove('dark');
    }, []);

    document.body.classList.remove('dark');

    return (
        <div className="flex flex-col h-screen">
            <section name="hero" className="bg-header">
                <div className="flex flex-col items-center pt-32 pb-16">
                    <div className="flex flex-col items-center w-100 mb-9">
                        <h3 class="text-primary text-sm sm:text-base font-bold">Discover new Restaurants</h3>

                        <div class="text-5xl sm:text-6xl lg:text-7xl xl:text-7xl font-bold tracking-wide text-white md:px-20 lg:px-4">
                            <h1 class="inline md:hidden">Delicious Food</h1>
                            <h1 class="hidden md:inline">Your favorite meals delivered</h1>
                        </div>
                        <h1 class="mt-2 sm:mt-2 text-4xl sm:text-6xl lg:text-7xl xl:text-7xl font-bold tracking-wide text-white px-8 sm:px-20 md:px-24 lg:px-24">at your doorstep</h1>

                        <div className="mt-8">
                            <Link to="https://download.seen-delivery.com/">
                                <button className="px-4 py-2 text-base text-white bg-secondary font-bold rounded-lg w-48 h-12">Download Now</button>
                            </Link>
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <img src={appImage} alt="App" className="" style={styles.appImage} />
                        <div className="flex justify-center mt-8">
                            {/* <a style={styles.appStoreBadge} href="https://apps.apple.com/app/seen-delivery/id6503015755" target="_blank" rel="noopener noreferrer">
                                <img src={appStoreBadge} style={styles.fill} alt="Download on the App Store" />
                            </a> */}
                            <a className="inline-block h-16" href="https://apps.apple.com/app/seen-delivery/id6503015755">
                                <img className="p-3 h-20" src={appStoreBadge} alt="Download on the App Store" />
                            </a>

                            <a className="inline-block" href="https://play.google.com/store/apps/details?id=com.seen.customer.android">
                                <img className="h-20" src={googlePlayBadge} alt="Get it on Google Play" />
                            </a>
                            {/* <a style={styles.googlePlayBadge} href="https://apps.apple.com/app/idYourAppId" target="_blank" rel="noopener noreferrer">
                                <img src={googlePlayBadge} alt="Get it on Google Play" />
                            </a> */}
                        </div>
                    </div>
                </div>
            </section>

            <section name="about" className="bg-white dark:bg-dark-dark-bg">
                <div className="flex flex-col items-center mt-8 pt-8 pb-16">
                    <h2 className="text-3xl text-red-600 font-bold mb-4">What is Seen?</h2>
                    <p className="text-lg text-gray-600 dark:text-gray-200 sm:w-1/2 lg:w-1/3 xl:w-1/4 text-center">Seen is an online food delivery app designed to bring your favorite meals directly to your doorstep with convenience and efficiency. Our platform connects you with a wide array of restaurants, offering a diverse selection of cuisines to satisfy any craving.</p>
                    <br></br>
                    <p className="text-lg text-gray-600 dark:text-gray-200 sm:w-1/2 lg:w-1/3 xl:w-1/4 text-center">Our mission is to provide a seamless and enjoyable experience for our customers, ensuring that you receive your food quickly and in perfect condition. We are committed to delivering exceptional service and making your dining experience as enjoyable as possible.</p>
                </div>
            </section>

            <section name="contact" className="bg-header">
                <div className="flex flex-col items-center mt-8 pt-8 pb-16">
                    <h2 className="text-3xl text-white dark:text-gray-200 mb-4">Contact Us</h2>
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)}>
                            <FormInput name="name" type="text" placeholder="Your Name" className="mb-2" />
                            <FormInput name="email" type="email" placeholder="Your Email" className="mb-2" />
                            <FormTextarea name="message" placeholder="Your Message" className="text-gray-600" />
                            <div className="flex justify-center">
                                <button type="submit" className="px-4 py-2 text-lg bg-white dark:bg-dark-dark-bg text-gray-600 dark:text-dark-gray-text rounded">Submit</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </section>
        </div>
    );
};

const styles = {
    appImage: {
        width: '300px',
        borderRadius: 16,
        borderWidth: 1,
        borderColor: '#fff6',
        opacity: 0.9,
    },
    appStoreBadge: {
        height: '50px',
    },
    googlePlayBadge: {
        width: '190px',
        height: '50px',
    },
    appGalleryBadge: {
        width: '170px',
        height: '50px',
    },
    fill: {
        width: '100%',
        height: '100%',
    }
};

export default LandingPage;