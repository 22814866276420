import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import { Link } from 'react-router-dom';
import api from '../../../services/apiService';
import { Plus, Trash } from '@phosphor-icons/react';
import { useApp } from '../../../contexts/AppContext';
import ReactHlsPlayer from 'react-hls-player';

const DashboardPostsPage = () => {
    const [posts, setPosts] = useState([]);
    const { selectedRestaurant, selectedBranch } = useApp();

    useEffect(() => {
        if (!selectedRestaurant) return;

        const fetchPosts = async () => {
            const response = await api.get(`restaurants/${selectedRestaurant.id}/posts`);

            if (response.data.success) {
                setPosts(response.data.posts);
            }
            else {
                console.error(response.data.message);
            }
        };

        fetchPosts();
    }, [selectedRestaurant, selectedBranch]);

    const deletePost = async (id) => {
        try {
            const response = await api.delete(`restaurants/${selectedRestaurant.id}/posts/${id}`);

            if (response.data.success) {
                setPosts(posts.filter((item) => item.id !== id));
            }
            else {
                console.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <DefaultLayout>
            <div className="container mx-auto p-4">
                <div className="flex justify-between">
                    <h1 className="text-2xl font-bold mb-4">Posts</h1>
                    <div className="inline-flex">
                        <Link to="/posts/create" className="inline-flex min-w-32 items-center justify-center gap-2 rounded-lg bg-primary text-sm text-white shadow-md">
                            <Plus size={20} />
                            Create Post
                        </Link>
                    </div>
                </div>

                {/* Show Post List */}
                <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {posts && posts.map((item) => (
                        <div className="bg-light-dark-bg dark:bg-dark-dark-bg p-4 rounded-lg shadow-lg relative" key={item.id}>
                            <Trash className="absolute top-2 right-2 cursor-pointer" size={20} onClick={() => deletePost(item.id)} color='red' />

                            <div to={`/posts/edit/${item.id}`}>
                                <h2 className="text-lg font-semibold">{item.name}</h2>
                                <p className="text-light-gray-text dark:text-dark-gray-text">{item.description}</p>
                                {/* <p className="text-light-gray-text dark:text-dark-gray-text">Price: {item.base_price.toFixed(2)}</p> */}
                                {/* <img src={item.restaurant.logo_image.url} alt={item.restaurant.logo_image.url} className="w-full h-48 object-cover rounded-lg mt-4" /> */}
                                {/* <video src={item.video.url} controls className="w-full h-96 object-cover rounded-lg mt-4" /> */}

                                {item.video.extension === 'm3u8' ? (
                                    <ReactHlsPlayer
                                        src={item.video.url}
                                        autoPlay={false}
                                        controls={true}
                                        width="100%"
                                        height="auto"
                                        className="w-full h-96 object-cover rounded-lg mt-4"
                                        hlsConfig={{
                                            maxBufferLength: 1,
                                            maxBufferSize: 0,
                                            lowLatencyMode: true,
                                        }}
                                    />
                                ) : (
                                    <video src={item.video.url} controls className="w-full h-96 object-cover rounded-lg mt-4" />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </DefaultLayout>
    );
};

export default DashboardPostsPage;