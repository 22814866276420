import { CircleNotch, FloppyDisk } from '@phosphor-icons/react';

const FormSubmit = ({ form, label = 'Save', icon = <FloppyDisk className="size-[1.125rem]" /> }) => {
  return (
    <button type="submit" disabled={form.formState.isSubmitting} className="inline-flex min-w-32 items-center justify-center gap-2 rounded-lg bg-primary px-5 py-2.5 text-sm text-light-white-text dark:text-dark-white-text shadow-md">
      {form.formState.isSubmitting && <CircleNotch className="size-[1.125rem] animate-spin" />}
      {!form.formState.isSubmitting && icon}
      <span>{label}</span>
    </button>
  );
};

export default FormSubmit;