import { Link, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { ArrowRight, CircleNotch } from '@phosphor-icons/react';
import FormLabel from '../../components/Form/FormLabel';
import FormError from '../../components/Form/FormError';
import FormInput from '../../components/Form/FormInput';
import AuthLayout from '../../layouts/AuthLayout';
import api from '../../services/apiService';
import { useApp } from '../../contexts/AppContext';

const SignUpPage = () => {
    const form = useForm();
    const { logIn } = useApp();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            const response = await api.post('register', {
                name: data.username,
                email: data.email,
                password: data.password,
                password_confirmation: data.confirmPassword
            });

            if (response.data.user && response.data.token) {
                logIn(response.data.user, response.data.token);
                
                navigate('/dashboard');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <AuthLayout>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="mx-auto w-full rounded-lg border border-white/5 bg-light-dark-bg dark:bg-dark-dark-bg sm:w-[32rem]">
                        <div className="bg-light-dark-bg-active dark:bg-dark-dark-bg-active px-6 py-4 text-center">
                            <Link to="/" className="mb-1 block text-3xl font-semibold">
                                <span className="">Seen<span className="text-primary">Delivery</span></span>
                            </Link>
                            <p className="text-xs">Create a free account.</p>
                        </div>
                        <div className="px-6 py-4">
                            <div className="mb-3">
                                <FormLabel htmlFor="username">Name</FormLabel>
                                <FormInput type="text" id="username" name="username" validation={{ required: "Username is required." }} />
                                <FormError name="username" />
                            </div>
                            <div className="mb-3">
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <FormInput type="text" id="email" name="email" validation={{ required: "Email is required.", pattern: { value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: "Invalid email address" } }} />
                                <FormError name="email" />
                            </div>
                            <div className="mb-3">
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <FormInput type="password" id="password" name="password" validation={{ required: "Password is required.", minLength: { value: 8, message: "Password must be at least 8 characters." } }} />
                                <FormError name="password" />
                            </div>
                            <div className="mb-4">
                                <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
                                <FormInput type="password" id="confirmPassword" name="confirmPassword" validation={{ required: "Confirm Password is required.", validate: (value) => value === form.watch('password') || "Passwords do not match." }} />
                                <FormError name="confirmPassword" />
                            </div>
                            <div className="mb-8">
                                <button disabled={form.formState.isSubmitting} type="submit" className="flex w-full items-center justify-center gap-2 rounded-lg bg-primary px-5 py-2.5 text-sm  focus:ring-2 focus:ring-primary/50 focus:ring-offset-white/5">
                                    Register
                                    {form.formState.isSubmitting && <CircleNotch className="size-4 animate-spin" />}
                                    {!form.formState.isSubmitting && <ArrowRight className="size-4" />}
                                </button>
                            </div>
                            <div className="text-center">
                                <p className="text-sm">Already have an account? <Link to="/login" className="text-primary">Login</Link></p>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </AuthLayout>
    );
};

export default SignUpPage;