import { useForm, FormProvider, Controller } from 'react-hook-form';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { X } from '@phosphor-icons/react';
import { useParams } from 'react-router-dom';
import DefaultLayout from '../../../layouts/DefaultLayout';
import FormLabel from '../../../components/Form/FormLabel';
import FormError from '../../../components/Form/FormError';
import FormInput from '../../../components/Form/FormInput';
import FormSubmit from '../../../components/Form/FormSubmit';
import api from '../../../services/apiService';
import { useEffect } from 'react';
import SingleVideoUploader from '../../../components/SingleVideoUploader';
import { useApp } from '../../../contexts/AppContext';

const PostFormPage = () => {
    const { id } = useParams();
    const { selectedRestaurant } = useApp();

    const isCreate = id === undefined;
    const isEdit = id !== undefined;

    const form = useForm();

    useEffect(() => {
        if (!isEdit) return;

        const fetchPost = async () => {
            try {
                const { data } = await api.get(`restaurants/${selectedRestaurant.id}/posts/${id}`);
                for (const field of Object.keys(data.post)) {
                    let value = data.post[field];
                    form.setValue(field, value);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchPost();
    }, [form, id, isEdit, selectedRestaurant]);

    const onSubmit = async (data) => {
        if (isCreate) {
            api.post(`restaurants/${selectedRestaurant.id}/posts`, data).then((createResponse) => {
                if (createResponse.data.success) {
                    console.log('Post created successfully.');
                }
                else {
                    console.log(createResponse.data.message);
                }
            }).catch((error) => {
                console.error(error);
                console.log(error.response?.data?.message || error.message);
            });
        } else {
            api.put(`restaurants/${selectedRestaurant.id}/posts/${id}`, data).then((updateResponse) => {
                if (updateResponse.data.success) {
                    console.log('Post updated successfully.');
                }
                else {
                    console.log(updateResponse.data.message);
                }
            }).catch((error) => {
                console.error(error);
                console.log(error.response?.data?.message || error.message);
            });
        }

        console.log(data);
    };

    return (
        <DefaultLayout>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="mb-8 flex flex-col gap-4 md:flex-row md:posts-end md:justify-between">
                        <div>
                            <h1 className="mb-2 text-2xl font-semibold text-white">
                                {isEdit ? 'Edit' : 'Create'} Post
                            </h1>
                            <p className="text-sm">
                                {isEdit ? 'Edit the post details.' : 'Create a new post for your menu.'}
                            </p>
                        </div>
                        <div className="flex gap-4">
                            <FormSubmit form={form} label={isEdit ? 'Save' : 'Create'} />
                            <button type="button" className="inline-flex min-w-32 posts-center justify-center gap-2 rounded-lg bg-light-dark-bg dark:bg-dark-dark-bg px-5 py-2.5 text-sm text-white shadow-md">
                                <X className="size-[1.125rem]" />
                                Cancel
                            </button>
                        </div>
                    </div>

                    <div className="rounded-lg border border-white/5 bg-light-dark-bg dark:bg-dark-dark-bg px-6 py-4">
                        <div className="mb-3">
                            <FormLabel htmlFor="description">Description</FormLabel>
                            <FormInput type="text" id="description" name="description" validation={{ required: "Description is required." }} />
                            <FormError name="description" />
                        </div>
                        <div className="mb-3">
                            <FormLabel htmlFor="description_ar">Description ( Arabic )</FormLabel>
                            <FormInput type="text" id="description_ar" name="description_ar" validation={{ required: "Arabic Description is required." }} />
                            <FormError name="description_ar" />
                        </div>
                        <div className="mb-3">
                            <FormLabel>Video</FormLabel>
                            <Controller
                                id="video_id"
                                name="video_id"
                                control={form.control}
                                render={({ field: { onChange, value } }) => (
                                    <SingleVideoUploader
                                        videoId={value || null}
                                        setVideoId={(ids) => onChange(ids)} />
                                )}
                            />
                            <FormError name="video_id" />
                        </div>
                    </div>

                    {/* TODO: Products list */}
                </form>
            </FormProvider>
        </DefaultLayout >
    );
};

export default PostFormPage;