const AuthLayout = ({ children }) => {
    return (
        <div className="dark">
            <div className="flex h-screen items-center justify-center overflow-hidden dark:bg-dark-darker-bg">
                <main className="w-full p-4 md:p-6 2xl:px-10">
                    {children}
                </main>
            </div>
        </div>
    );
};
  
export default AuthLayout;
  