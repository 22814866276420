import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';

const DashboardHomePage = () => {
    return (
        <DefaultLayout>
            
        </DefaultLayout>
    );
};

export default DashboardHomePage;