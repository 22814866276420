import React, { useEffect } from 'react';

const DownloadPage = () => {
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            // Redirect to Google Play Store
            window.location.href = "https://play.google.com/store/apps/details?id=com.seen.customer.android";
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Redirect to Apple App Store
            window.location.href = "https://apps.apple.com/app/seen-delivery/id6503015755";
        } else {
            // Default redirect or handle other platforms
            //   console.log("Not on iOS or Android");
            window.location.href = "https://app.seen-delivery.com";
        }
    }, []);

    return (
        <div>
            <p>Redirecting to the appropriate app store...</p>
        </div>
    );
};

export default DownloadPage;
