import { useEffect, useRef, useState, Fragment } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { House, ShoppingBag, X, User, SignOut, Bag, List, Building, TreeStructure, Newspaper, ForkKnife } from '@phosphor-icons/react';
import Select from 'react-select';
import { useApp } from '../contexts/AppContext';

function SidebarTabs() {
  const location = useLocation();
  const { pathname } = location;
  const { selectedRestaurant, selectedBranch } = useApp();

  const [tabs, setTabs] = useState([
    {
      name: 'Create',
      items: [
        {
          label: 'Create Restaurant',
          icon: <Building className="size-5" />,
          to: '/restaurant/create',
          active: pathname.includes('restaurant/create'),
        },
        {
          label: 'Create Restaurant Branch',
          icon: <TreeStructure className="size-5" />,
          to: '/restaurant/branch/create',
          active: pathname.includes('restaurant/branch/create'),
        }
      ]
    }
  ]);

  useEffect(() => {
    let newTabs = [];

    if (selectedRestaurant) {
      newTabs = newTabs.concat({
        name: 'Restaurant',
        items: [
          {
            label: 'Edit',
            icon: <ForkKnife className="size-5" />,
            to: '/restaurant/edit',
            active: pathname.includes('restaurant/edit'),
          },
          {
            label: 'Posts',
            icon: <Newspaper className="size-5" />,
            to: '/posts',
            active: pathname.includes('posts'),
          },
          {
            label: 'Menu',
            icon: <List className="size-5" />,
            to: '/menu',
            active: pathname.includes('menu'),
          }
        ]
      });
    }

    if (selectedRestaurant && selectedBranch) {
      newTabs = newTabs.concat({
        name: 'Branch',
        items: [
          {
            label: 'Orders',
            icon: <Bag className="size-5" />,
            to: '/orders',
            active: pathname.includes('orders'),
          }
        ]
      });
    }

    if (selectedRestaurant) {
      newTabs = newTabs.concat({
        name: 'Create',
        items: [
          {
            label: 'Create Restaurant',
            icon: <Building className="size-5" />,
            to: '/restaurant/create',
            active: pathname.includes('restaurant/create'),
          },
          {
            label: 'Create Restaurant Branch',
            icon: <TreeStructure className="size-5" />,
            to: '/restaurant/branch/create',
            active: pathname.includes('restaurant/branch/create'),
          }
        ]
      });
    } else {
      newTabs = newTabs.concat({
        name: 'Create',
        items: [
          {
            label: 'Create Restaurant',
            icon: <Building className="size-5" />,
            to: '/restaurant/create',
            active: pathname.includes('restaurant/create'),
          }
        ]
      });
    }

    setTabs(newTabs);
  }, [selectedRestaurant, selectedBranch, pathname]);


  return (
    <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
      <nav>
        {tabs.map((tab) => (
          <div key={tab.name}>
            <p className="text-light-sidebar-text dark:text-dark-sidebar-text ml-4 mb-2 font-bold text-sm uppercase">{tab.name}</p>
            <ul className="mb-6 flex flex-col gap-1.5">
              {tab.items.map((item) => (
                <li key={item.label} className={`border-l-[3px] ${item.active ? 'border-l-primary text-primary' : 'border-transparent text-light-sidebar-text dark:text-dark-sidebar-text'}`}>
                  <NavLink to={item.to} className="group relative flex items-center gap-4 px-6 py-2 font-medium duration-300 ease-in-out hover:opacity-90">
                    {item.icon}
                    {item.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>
    </div>
  )
}

function RestaurantSelect() {
  const { selectedRestaurant, setSelectedRestaurant, selectedBranch, setSelectedBranch, restaurants } = useApp();

  const onSelectRestaurant = (restaurantId) => {
    const restaurant = restaurants.find(restaurant => restaurant.id === restaurantId);

    setSelectedRestaurant(restaurant);
    setSelectedBranch(null);
  }

  const onSelectBranch = (branchId) => {
    const branch = selectedRestaurant.branches.find(branch => branch.id === branchId);

    setSelectedBranch(branch);
  }

  const restaurantOptions = restaurants.map(restaurant => ({ value: restaurant.id, label: restaurant.name }));
  const branchOptions = selectedRestaurant && selectedRestaurant.branches ? selectedRestaurant.branches.map(branch => ({ value: branch.id, label: branch.address })) : [];

  console.log(selectedRestaurant);
  console.log(restaurantOptions);
  console.log(restaurantOptions.find((option) => option.value === selectedRestaurant.id));

  return (
    <>
      <Select
        inputId="branch"
        value={selectedBranch ? branchOptions.find((option) => option.value === selectedBranch.id) : null}
        options={branchOptions}
        onChange={(selectedOption) => onSelectBranch(selectedOption.value)}
        className="react-dark-select-container dark:react-dark-select-container"
        classNamePrefix="react-dark-select"
        placeholder="Select Branch"
        menuPlacement='top'
        isSearchable={false}
      />

      <Select
        inputId="restaurant"
        value={selectedRestaurant ? restaurantOptions.find((option) => option.value === selectedRestaurant.id) : null}
        options={restaurantOptions}
        onChange={(selectedOption) => onSelectRestaurant(selectedOption.value)}
        className="react-dark-select-container dark:react-dark-select-container mt-4"
        classNamePrefix="react-dark-select"
        placeholder="Select Restaurant"
        menuPlacement='top'
        isSearchable={false}
      />
    </>
  )
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const { logOut, user } = useApp();

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());

    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded');
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  const navigate = useNavigate();

  const onLogOut = () => {
    logOut();

    navigate('/login');
  };

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-[2] flex h-screen w-72 flex-col justify-between border-r border-r-light-dark-bg-active dark:border-r-dark-dark-bg-active bg-light-sidebar-bg dark:bg-dark-sidebar-bg duration-300 ease-linear lg:static lg:translate-x-0 ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}>
      <div>
        <div className="mb-2 flex items-center justify-between gap-2 border-b border-light-dark-bg dark:border-dark-dark-bg-active px-6 py-4 lg:px-7">
          <NavLink to="/dashboard" className="text-2xl lg:text-3xl">
            <span className="text-white">Seen</span> <span className="text-primary">Delivery</span>
          </NavLink>

          <div className="flex items-center gap-4">
            <button
              ref={trigger}
              onClick={() => setSidebarOpen(!sidebarOpen)}
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              className="flex items-center justify-center lg:hidden"
            >
              <X className="size-6 text-white" />
            </button>
          </div>
        </div>

        <SidebarTabs />
      </div>
      <div>
        <div className="mb-4 px-6 py-3">
          <RestaurantSelect />

          <div className="flex items-center justify-between mt-3">
            <NavLink to="/user" className="flex items-center gap-2">
              <span className="rounded bg-dark-bg-active p-3.5">
                <User className="size-4 text-white" />
              </span>
              <div>
                <p className="text-sm font-medium text-white">
                  {user?.name}
                </p>
                <p className="text-xs">
                  {user?.email}
                </p>
              </div>
            </NavLink>
            <button type="button" onClick={onLogOut}>
              <SignOut className="size-6 hover:text-white" />
            </button>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;