import { useState, useEffect } from 'react';
// import { toast } from 'react-toastify';
import Uppy from '@uppy/core';
import XHR from '@uppy/xhr-upload';
import { DragDrop } from '@uppy/react';
import '@uppy/core/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import { ArrowLeft, ArrowRight, Plus, CircleNotch, X, Check, Image } from '@phosphor-icons/react';
import Dialog from './Dialog';
import api from '../services/apiService';
import { useApp } from '../contexts/AppContext';

const SingleImageUploader = ({ imageId, setImageId }) => {
  const store = useApp();
  const [allImages, setAllImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const perPage = 3;

  const fetchImages = async () => {
    try {
      const response = await api.get(`images`);
      if (response.data.success) {
        setAllImages(response.data.images.reverse());
      } else {
        setAllImages([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!store.user) return;

    fetchImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.user]);

  const [isUploading, setIsUploading] = useState(false);

  const [uppy] = useState(() => {
    return new Uppy({
      maxFileSize: 20 * 1024 * 1024, // 20MB
      allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif', '.avif'],
      autoProceed: true,
    }).use(XHR, {
      fieldName: 'image',
    });
  });

  uppy.setOptions({
    maxNumberOfFiles: 1,
  });

  uppy.getPlugin('XHRUpload').setOptions({
    endpoint: `${api.defaults.baseURL}images`,
    headers: {
      Authorization: `Bearer ${store.userToken}`,
    },
  });

  useEffect(() => {
    uppy.on('upload', () => {
      setIsUploading(true);
    });

    uppy.on('upload-error', (_file, error) => {
      //   toast.error(`Failed to upload image (${error.message}).`);
      setIsUploading(false);
    });

    uppy.on('upload-success', async () => {
      await fetchImages();
      setIsUploading(false);
    });
  }, [uppy]);

  const [selectedImageId, setSelectedImageId] = useState([]);

  useEffect(() => {
    setSelectedImageId(imageId);
  }, [imageId]);

  const onSelectImage = (image) => {
    // if (maxImages > 1) {
    //   if (selectedImageIds.includes(image.id)) {
    //     setSelectedImageIds(selectedImageIds.filter((id) => id !== image.id));
    //     return;
    //   }

    //   if (selectedImageIds.length >= maxImages) {
    //     // toast.error(`You can only select up to ${maxImages} images.`);
    //     return;
    //   }

    //   setSelectedImageIds([...selectedImageIds, image.id]);
    // } else {

    // }

    setSelectedImageId(image.id);
  };

  const onConfirm = async () => {
    setImageId(selectedImageId);
    setIsOpen(false);
  };

  const getUrlForImageId = (imageId) => {
    const image = allImages.find((image) => image.id === imageId);
    return image ? image.url : '';
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-4 @lg/form:grid-cols-2 @4xl/form:grid-cols-3 @7xl/form:grid-cols-4 @8xl/form:grid-cols-5">
        <div className="relative flex h-48 cursor-pointer flex-col items-center justify-center gap-2 overflow-hidden rounded-md border border-white/5 bg-light-darker-bg dark:bg-dark-darker-bg dark:bg-dark-darker-bg p-4 text-center transition hover:text-white" onClick={() => { fetchImages(); setIsOpen(true) }}>
          <Image className="size-[3rem]" />
          <span>Tap to select an image from your content gallery.</span>
        </div>

        {imageId != null && (
          <div key={imageId} className="relative flex items-center justify-center overflow-hidden rounded-md border border-white/5 bg-light-darker-bg dark:bg-dark-darker-bg dark:bg-dark-darker-bg">
            <img src={getUrlForImageId(imageId)} alt={imageId} className="h-48 w-full object-contain" />
            <button type="button" onClick={() => setImageId(null)} className="absolute right-0 top-0 rounded-bl-md bg-black/50 p-1 text-white">
              <X className="size-[1.125rem]" />
            </button>
          </div>
        )}
      </div>

      <Dialog isOpen={isOpen} close={() => setIsOpen(false)} title={`Select Image`}>
        <div className="mb-4">
          <div className="mb-2 grid grid-cols-2 gap-4">
            <DragDrop uppy={uppy} width="100%" height="12rem" />
            {allImages.slice((page - 1) * perPage, page * perPage).map((image) => (
              <div key={image.id} onClick={() => onSelectImage(image)} className="relative flex items-center justify-center overflow-hidden rounded-md border border-white/5 bg-light-darker-bg dark:bg-dark-darker-bg dark:bg-dark-darker-bg">
                <img src={image.url} alt={image.id} className="h-48 w-full object-contain" />
                {selectedImageId === image.id && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black/60">
                    <Check className="size-[3rem] text-white" />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center gap-4">
            <button type="button" onClick={() => setPage(page - 1)} disabled={page === 1} className="inline-flex items-center justify-center gap-2 rounded-lg bg-light-dark-bg-active dark:bg-dark-dark-bg-active px-5 py-2.5 text-sm text-white shadow-md disabled:opacity-50">
              <ArrowLeft className="size-[1.125rem]" />
            </button>
            <button type="button" onClick={() => setPage(page + 1)} disabled={page * perPage >= allImages.length} className="inline-flex items-center justify-center gap-2 rounded-lg bg-light-dark-bg-active dark:bg-dark-dark-bg-active px-5 py-2.5 text-sm text-white shadow-md disabled:opacity-50">
              <ArrowRight className="size-[1.125rem]" />
            </button>
          </div>
        </div>
        <div className="flex justify-end gap-2">
          <button type="button" className="inline-flex min-w-32 items-center justify-center gap-2 rounded-lg bg-light-dark-bg-active dark:bg-dark-dark-bg-active px-5 py-2.5 text-sm text-white shadow-md" onClick={() => setIsOpen(false)}>
            <X className="size-[1.125rem]" />
            Cancel
          </button>
          <button type="button" onClick={onConfirm} disabled={isUploading} className="inline-flex min-w-32 items-center justify-center gap-2 rounded-lg bg-primary px-5 py-2.5 text-sm text-white shadow-md">
            {isUploading && <CircleNotch className="size-[1.125rem] animate-spin" />}
            {!isUploading && <Plus className="size-[1.125rem]" />}
            Confirm
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default SingleImageUploader;