import { useForm, FormProvider } from 'react-hook-form';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { X } from '@phosphor-icons/react';
import { useParams } from 'react-router-dom';
import DefaultLayout from '../../../layouts/DefaultLayout';
import FormLabel from '../../../components/Form/FormLabel';
import FormError from '../../../components/Form/FormError';
import FormInput from '../../../components/Form/FormInput';
import FormSubmit from '../../../components/Form/FormSubmit';
import api from '../../../services/apiService';
import { useEffect } from 'react';
import { useApp } from '../../../contexts/AppContext';

const CategoryFormPage = () => {
    const { id } = useParams();
    const { selectedRestaurant } = useApp();

    const isCreate = id === undefined;
    const isEdit = id !== undefined;

    const form = useForm({
        defaultValues: {
            name: '',
            visibility: 'public',
            addon_groups: [],
        },
    });

    //   const navigate = useNavigate();

    useEffect(() => {
        if (!isEdit) return;

        const fetchItem = async () => {
            try {
                const { data } = await api.get(`restaurants/${selectedRestaurant.id}/items/${id}`);

                for (const field of Object.keys(data.item)) {
                    let value = data.item[field];
                    form.setValue(field, value);
                }
            } catch (error) {
                console.error(error);
                // toast.error(`Failed to load group data (${error.response?.data?.message || error.message}).`);
                // navigate('/groups');
            }
        };

        fetchItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (data) => {
        if (isCreate) {
            try {
                const createResponse = await api.post(`restaurants/${selectedRestaurant.id}/categories`, data);

                if (createResponse.data.success) {
                    console.log('Item created successfully.');
                }
                else {
                    console.log(createResponse.data.message);
                }
            } catch (error) {
                console.error(error);
                console.log(error.response?.data?.message || error.message);
            }
        } else {
            try {
                const updateResponse = await api.put(`restaurants/${selectedRestaurant.id}/categories/${id}`, data);

                if (updateResponse.data.success) {
                    console.log('Item updated successfully.');
                }
                else {
                    console.log(updateResponse.data.message);
                }
            } catch (error) {
                console.error(error);
                console.log(error.response?.data?.message || error.message);
            }
        }
    };

    return (
        <DefaultLayout>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="mb-8 flex flex-col gap-4 md:flex-row md:items-end md:justify-between">
                        <div>
                            <h1 className="mb-2 text-2xl font-semibold text-white">
                                {isEdit ? 'Edit' : 'Create'} Item
                            </h1>
                            <p className="text-sm">
                                {isEdit ? 'Edit the item details.' : 'Create a new item for your menu.'}
                            </p>
                        </div>
                        <div className="flex gap-4">
                            <FormSubmit form={form} label={isEdit ? 'Save' : 'Create'} />
                            <button type="button" className="inline-flex min-w-32 items-center justify-center gap-2 rounded-lg bg-light-dark-bg dark:bg-dark-dark-bg px-5 py-2.5 text-sm text-white shadow-md">
                                <X className="size-[1.125rem]" />
                                Cancel
                            </button>
                        </div>
                    </div>

                    <div className="rounded-lg border border-white/5 bg-light-dark-bg dark:bg-dark-dark-bg px-6 py-4">
                        <div className="mb-3">
                            <FormLabel htmlFor="name">Name</FormLabel>
                            <FormInput type="text" id="name" name="name" validation={{ required: "Name is required." }} />
                            <FormError name="name" />
                        </div>
                        <div className="mb-3">
                            <FormLabel htmlFor="name_ar">Name ( Arabic )</FormLabel>
                            <FormInput type="text" id="name_ar" name="name_ar" validation={{ required: "Arabic Name is required." }} />
                            <FormError name="name_ar" />
                        </div>
                    </div>
                </form>
            </FormProvider>
        </DefaultLayout >
    );
};

export default CategoryFormPage;