const FormLabel = ({ htmlFor, help, children }) => {
  return (
    <div className="mb-2">
      <label htmlFor={htmlFor} className="text-base font-medium text-light-white-text dark:text-dark-white-text">
        {children}
      </label>
      {help && <p className="text-xs text-light-gray-text dark:text-dark-gray-text">{help}</p>}
    </div>
  );
};

export default FormLabel;