import React, { useState } from 'react';
import './DeleteAccount.css';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import FormLabel from '../components/Form/FormLabel';
import FormInput from '../components/Form/FormInput';
import FormError from '../components/Form/FormError';

const Modal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-500 fadeIn">
            <div className="bg-light-dark-bg dark:bg-dark-dark-bg text-white p-6 rounded-lg scaleUp">
                <h2 className="text-xl font-semibold mb-4">Confirm Account Deletion</h2>
                <p>Are you sure you want to delete your account? This action cannot be undone.</p>
                <div className="flex justify-end space-x-4 mt-4">
                    <button onClick={onClose} className="px-4 py-2 bg-gray-600 rounded-lg hover:bg-gray-500">Cancel</button>
                    <button onClick={onConfirm} className="px-4 py-2 bg-red-700 rounded-lg hover:bg-red-600">Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default function DeleteAccountPage() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const form = useForm();

    const handleSubmit = (data) => {
        setIsModalOpen(true);

        setPhoneNumber(data['phone-number']);
    };

    const handleDeleteAccount = () => {
        axios.delete('https://api.seen-delivery.com/api/v1/guest/delete-account', { phoneNumber }).finally(() => {
            alert('Account deletion request submitted.');
        });

        // Close modal after confirmation
        setIsModalOpen(false);
    };

    return (
        <div className="dark">
            <div className="flex items-center justify-center min-h-screen bg-light-darker-bg dark:bg-dark-darker-bg">
                <div className="max-w-xl w-full mx-auto p-8 bg-light-dark-bg dark:bg-dark-dark-bg text-light-gray-text dark:text-light-gray-text rounded-lg shadow-md">
                    <h1 className="text-3xl font-bold text-center mb-4">Seen Delivery</h1>
                    <h2 className="text-2xl font-semibold text-center mb-6">Seen Mobile Application</h2>
                    <p className="mb-6">To request the deletion of your account, please follow the steps below:</p>
                    <ol className="list-decimal list-inside mb-6 pl-4">
                        <li>Enter your phone number associated with your account.</li>
                        <li>Submit the form to send your account deletion request.</li>
                        <li>Our team will review your request and may contact you for further verification.</li>
                        <li>Upon verification, your account will be deleted.</li>
                    </ol>

                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(handleSubmit)} className="mb-6">
                            <FormLabel htmlFor="phone-number">Phone Number:</FormLabel>
                            <FormError name="phone-number" />
                            <FormInput type="tel" id="phone-number" name="phone-number" inputMode="numeric" className="form-input px-4 py-2 bg-gray-700 text-white rounded-lg w-full mb-4" validation={{ required: "Phone number is required.", pattern: { value: /^[0-9]{9}$/, message: "Phone number must be all numbers and 10 digits long." } }} />

                            <button type="submit" className="px-4 py-2 bg-red-800 text-white rounded-lg hover:bg-red-900 w-full">Submit Request</button>
                        </form>
                    </FormProvider>

                    <section>
                        <h3 className="text-xl font-semibold mb-2 text-gray-400">Data Deletion Policy</h3>
                        <p>Upon the deletion of your account, all personal data associated with your account will be permanently deleted, including your delivery history and account details.</p>
                    </section>
                </div>

                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onConfirm={handleDeleteAccount} />
            </div>
        </div>
    );
};