import React, { useEffect, useState, useCallback } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import api from '../../services/apiService';
import { useApp } from '../../contexts/AppContext';

const OrderList = ({ orders, fetchOrders }) => {
    const handleAccept = async (orderId) => {
        await api.post(`orders/${orderId}/accept`);

        fetchOrders();
    };

    const handleDecline = async (orderId) => {
        await api.post(`orders/${orderId}/decline`);

        fetchOrders();
    };

    const handleReady = async (orderId) => {
        await api.post(`orders/${orderId}/ready`);

        fetchOrders();
    };

    return (
        <div className="flex flex-col">
            {orders && orders.map((order) => (
                <div key={order.id} className="p-4 m-2 rounded-lg shadow-lg border border-white/5 bg-light-dark-bg dark:bg-dark-dark-bg">
                    <p className="font-bold text-lg">Order ID: {order.id}</p>
                    <p>Status: {order.status}</p>
                    <p>Restaurant ID: {order.restaurant_id}</p>
                    <p>Restaurant Branch ID: {order.restaurant_branch_id}</p>
                    <p>Created At: {order.created_at}</p>
                    <div>
                        {order.items && order.items.map((item, itemIndex) => (
                            <>
                                <div key={itemIndex} className="mt-2 flex justify-between">
                                    <p className="text-lg">Item: {item.name} ({item.quantity})</p>
                                    <p className="text-lg">Price: {item.total_price}</p>
                                </div>
                                {item.addons && item.addons.map((addon, addonIndex) => (
                                    <div key={addonIndex} className="flex justify-between">
                                        <p className="text-sm">Addon: {addon.name} ({addon.quantity})</p>
                                        <p className="text-sm">Price: {addon.price}</p>
                                    </div>
                                ))}
                            </>
                        ))}
                    </div>

                    {order.status === 'waiting_for_store' && (
                        <div className="mt-4">
                            <button onClick={() => handleAccept(order.id)} className="inline-flex min-w-32 items-center justify-center gap-2 rounded-lg bg-primary px-5 py-2.5 text-sm text-white shadow-md mr-2">
                                Accept
                            </button>
                            <button onClick={() => handleDecline(order.id)} className="inline-flex min-w-32 items-center justify-center gap-2 rounded-lg bg-secondary px-5 py-2.5 text-sm text-white shadow-md">
                                Decline
                            </button>
                        </div>
                    )}

                    {order.status === 'preparing' && (
                        <div className="mt-4">
                            <button onClick={() => handleReady(order.id)} className="inline-flex min-w-32 items-center justify-center gap-2 rounded-lg bg-primary px-5 py-2.5 text-sm text-white shadow-md">
                                Ready
                            </button>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

const DashboardOrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const { selectedRestaurant, selectedBranch } = useApp();

    const fetchOrders = useCallback(async () => {
        const response = await api.get(`restaurants/${selectedRestaurant.id}/branches/${selectedBranch.id}/orders`);

        if (response.data.success) {
            setOrders(response.data.orders);
        } else {
            console.error(response.data.message);
        }
    }, [selectedRestaurant.id, selectedBranch.id]);

    useEffect(() => {
        fetchOrders();

        const intervalId = setInterval(fetchOrders, 5000);

        return () => clearInterval(intervalId);
    }, [fetchOrders]);

    return (
        <DefaultLayout>
            <div className="container mx-auto p-4">
                <h1 className="text-2xl font-bold mb-4">Orders</h1>
                <OrderList orders={orders} fetchOrders={fetchOrders} />
            </div>
        </DefaultLayout>
    );
};

export default DashboardOrdersPage;