import React, { createContext, useContext, useEffect, useState } from "react";
import api from "../services/apiService";

const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userToken, setUserToken] = useState(null);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [restaurants, setRestaurants] = useState([]);

    useEffect(() => {
        // setUserToken();

        // if () {
        //     const response = await api.get('', {
        //         headers: {
        //             Authorization: `Bearer ${localToken}`
        //         }
        //     });

        //     if (response.data.success) {
        //         store.logIn(response.data.user, localToken);
        //     } else {
        //         localStorage.removeItem('token');
        //     }
        // }
        const localToken = localStorage.getItem('token');

        api.get('', {
            headers: {
                Authorization: `Bearer ${localToken}`
            }
        }).then((response) => {
            if (response.data.success) {
                logIn(response.data.user, localToken);
            } else {
                localStorage.removeItem('token');
            }
        }
        ).catch((error) => {
            console.error(error);
            localStorage.removeItem('token');
        });
    }, []);

    useEffect(() => {
        const interceptorId = api.interceptors.request.use((config) => {
            if (userToken) {
                config.headers.Authorization = `Bearer ${userToken}`;
            }

            return config;
        });

        return () => api.interceptors.request.eject(interceptorId);
    }, [userToken]);

    useEffect(() => {
        if (!userToken) return;

        const fetchRestaurants = async () => {
            try {
                const { data } = await api.get('restaurants');

                if (data.success) {
                    setRestaurants(data.restaurants);
                }

                return data.restaurants;
            } catch (error) {
                console.error(error);
            }
        };

        fetchRestaurants().then((restaurants) => {
            const selectedRestaurantId = localStorage.getItem('restaurant_id');

            const newRestaurant = restaurants[0];

            setSelectedRestaurant(newRestaurant);

            console.log(restaurants);
            console.log(newRestaurant);

            if (newRestaurant && newRestaurant.branches.length > 0) {
                const selectedBranchId = localStorage.getItem('branch_id');

                const newBranch = newRestaurant.branches[0];

                setSelectedBranch(newBranch);

                console.log(newBranch);
            }
        });
    }, [userToken]);

    const logIn = (user, token) => {
        localStorage.setItem('token', token);

        setUser(user);
        setUserToken(token);
    }

    const logOut = () => {
        localStorage.removeItem('token');

        setUser(null);
        setUserToken(null);
    }

    return (
        <AppContext.Provider value={{
            user,
            userToken,
            logIn,
            logOut,
            selectedRestaurant,
            setSelectedRestaurant,
            selectedBranch,
            setSelectedBranch,
            restaurants,
            setRestaurants
        }}>
            {children}
        </AppContext.Provider>
    )
};

const useApp = () => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error('useApp must be used within an AppProvider');
    }

    return context;
};

export { AppContext, AppProvider, useApp };