import { useState } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const DefaultLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="dark flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex h-full flex-col overflow-y-auto p-4 md:p-6 2xl:px-10 bg-light-darker-bg dark:bg-dark-darker-bg">
          {children}
        </main>
      </div>
    </div>
  );
};

export default DefaultLayout;