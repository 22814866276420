import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import api from '../../services/apiService';
import FormSubmit from '../../components/Form/FormSubmit';
import FormInput from '../../components/Form/FormInput';
import FormLabel from '../../components/Form/FormLabel';
import FormTextarea from '../../components/Form/FormTextarea';
import FormError from '../../components/Form/FormError';
import SingleImageUploader from '../../components/SingleImageUploader';
import { useApp } from '../../contexts/AppContext';

const RestaurantFormPage = ({ isEdit }) => {
    const { selectedRestaurant } = useApp();

    const form = useForm();

    const onSubmit = async (data) => {
        if (isEdit) {
            try {
                const restaurantUpdateResponse = await api.put(`restaurants/${selectedRestaurant.id}`, data);

                console.log(restaurantUpdateResponse.data);
            } catch (error) {
                console.error(error);
            }
        } else {
            try {
                const restaurantCreateResponse = await api.post('restaurants', data);

                if (restaurantCreateResponse.data.success) {
                    form.reset();
                }

                console.log(restaurantCreateResponse.data);
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (!isEdit) return;

        const fetchStore = async () => {
            try {
                const response = await api.get(`restaurants/${selectedRestaurant.id}`);

                if (response.data.success) {
                    form.reset(response.data.restaurant);
                } else {
                    console.error(response.data.message);
                }
            } catch (error) {
                console.error(error);
            }
        }

        fetchStore();
    }, [form, selectedRestaurant, isEdit]);

    useEffect(() => {
        form.reset({
            'name': '',
            'name_ar': '',
            'short_description': '',
            'short_description_ar': '',
            'logo_image_id': null,
            'header_image_id': null
        });
    }, [isEdit, form]);
    
    const fields = [
        {
            section: 'General',
            fields: [
                {
                    name: 'name',
                    label: 'Restaurant Name',
                    type: 'text',
                    validation: { required: 'Restaurant Name is required' }
                },
                {
                    name: 'name_ar',
                    label: 'Restaurant Name (Arabic)',
                    type: 'text',
                    validation: { required: 'Restaurant Name (AR) is required' }
                },
                {
                    name: 'short_description',
                    label: 'Short Description',
                    type: 'text',
                    validation: { required: 'Short Description is required' }
                },
                {
                    name: 'short_description_ar',
                    label: 'Short Description (Arabic)',
                    type: 'text',
                    validation: { required: 'Short Description (AR) is required' }
                },
                {
                    name: 'logo_image_id',
                    label: 'Logo Image',
                    type: 'image',
                    validation: { required: 'Logo Image is required' }
                },
                {
                    name: 'header_image_id',
                    label: 'Header Image',
                    type: 'image',
                    validation: { required: 'Header Image is required' }
                }
            ]
        }
    ];

    const [isImageUploaderOpen, setIsImageUploaderOpen] = useState(false);

    return (
        <DefaultLayout>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="mb-8 flex flex-col gap-4 md:flex-row md:items-end md:justify-between">
                        <div>
                            <h1 className="mb-2 text-2xl font-semibold text-light-white-text dark:text-dark-white-text">
                                Shop
                            </h1>
                            <p className="text-sm">
                                Manage your shop settings.
                            </p>
                        </div>
                        <div className="flex gap-4">
                            <FormSubmit form={form} label="Create" />
                        </div>
                    </div>

                    <div className="flex flex-col gap-4">
                        {fields.map((section, index) => (
                            <div key={index} className="rounded-lg border border-white/5 bg-light-dark-bg dark:bg-dark-dark-bg">
                                <div className="border-b border-white/5 px-6 py-4">
                                    <h2 className="text-lg font-semibold text-light-white-text dark:text-dark-white-text">{section.section}</h2>
                                </div>
                                <div className="px-6 py-4">
                                    {section.fields.map((field, index) => (
                                        <div key={index} className="mb-3">
                                            <FormLabel htmlFor={field.name}>{field.label}</FormLabel>
                                            {['text', 'email', 'url', 'number', 'password'].includes(field.type) && (
                                                <FormInput id={field.name} name={field.name} type={field.type} validation={field.validation} />
                                            )}
                                            {field.type === 'textarea' && (
                                                <FormTextarea id={field.name} name={field.name} rows="4" validation={field.validation} />
                                            )}
                                            {field.type === 'image' && (
                                                <Controller
                                                    id={field.name}
                                                    name={field.name}
                                                    control={form.control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <SingleImageUploader
                                                            isOpen={isImageUploaderOpen}
                                                            setIsOpen={setIsImageUploaderOpen}
                                                            imageId={value || null}
                                                            setImageId={(ids) => onChange(ids)}
                                                            maxImages={1} />
                                                    )}
                                                    rules={field.validation}
                                                />
                                            )}
                                            <FormError name={field.name} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </form>
            </FormProvider>
        </DefaultLayout>
    );
};

export default RestaurantFormPage;