// import React from 'react';
// import { Link } from 'react-router-dom';

// const LoginPage = () => {
//     const styles = {
//         container: {
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             height: '100vh'
//         },
//         form: {
//             display: 'flex',
//             flexDirection: 'column',
//             width: '300px',
//             padding: '20px',
//             borderRadius: '5px',
//             boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)'
//         },
//         input: {
//             marginBottom: '10px',
//             padding: '10px',
//             fontSize: '16px',
//             borderRadius: '5px',
//             border: '1px solid #ddd'
//         },
//         loginButton: {
//             padding: '10px',
//             fontSize: '16px',
//             backgroundColor: '#007BFF',
//             color: '#fff',
//             border: 'none',
//             borderRadius: '5px',
//             cursor: 'pointer',
//             justifyContent: 'center',
//             display: 'flex',
//             textDecoration: 'none',
//         },
//         heading: {
//             textAlign: 'center'
//         }
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         console.log('Login form submitted');
//     }

//     return (
//         <div style={styles.container}>
//             <form style={styles.form} onSubmit={handleSubmit} className="bg-light-dark-bg dark:bg-dark-dark-bg">
//                 <h1 style={styles.heading}>Login</h1>
//                 <input style={styles.input} name="username" type="text" placeholder="Username" />
//                 <input style={styles.input} name="password" type="password" placeholder="Password" />
//                 <Link to="/dashboard" style={styles.loginButton}>
//                     Login
//                 </Link>
//             </form>
//         </div>
//     );
// };

// export default LoginPage;

import { Link, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
// import { toast } from 'react-toastify';
import { ArrowRight, CircleNotch } from '@phosphor-icons/react';
import FormLabel from '../../components/Form/FormLabel';
import FormError from '../../components/Form/FormError';
import FormInput from '../../components/Form/FormInput';
import AuthLayout from '../../layouts/AuthLayout';
import { loginUser } from '../../services/authService';
import { useApp } from '../../contexts/AppContext';

const LoginPage = () => {
    const form = useForm();
    const { logIn } = useApp();
    const navigate = useNavigate();
    //   const { executeRecaptcha } = useGoogleReCaptcha();

    const onSubmit = async (data) => {
        // try {
        //   const recaptchaToken = await executeRecaptcha('login');
        //   const response = await api.post('login', {
        //     email: data.email,
        //     password: data.password,
        //     google_recaptcha_token_v3: recaptchaToken
        //   });

        //   if (response.data.user && response.data.token) {
        //     store.login(response.data.user, response.data.token);
        //     navigate('/dashboard');
        //     toast.success('Logged in successfully.');
        //   } else {
        //     toast.error('Failed to login.');
        //   }
        // } catch (error) {
        //   console.error(error);
        //   toast.error(error.response?.data?.message || error.message);
        // }

        try {
            const response = await loginUser(data.email, data.password);
            if (response.success) {
                logIn(response.user, response.token);

                navigate('/dashboard');
            } else {
                console.error(response.message);
            }
        }
        catch (error) {
            console.error(error);
        }
    };

    return (
        <AuthLayout>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="mx-auto w-full rounded-lg border border-white/5 bg-light-dark-bg dark:bg-dark-dark-bg sm:w-[32rem]">
                        <div className="bg-light-dark-bg-active dark:bg-dark-dark-bg-active px-6 py-4 text-center">
                            <Link to="/" className="mb-1 block text-3xl font-semibold">
                                <span className="">Seen</span> <span className="text-primary">Delivery</span>
                            </Link>
                            <p className="text-xs">Log in to your account.</p>
                        </div>
                        <div className="px-6 py-4">
                            <div className="mb-3">
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <FormInput type="text" id="email" name="email" validation={{ required: "Email is required.", pattern: { value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: "Invalid email address" } }} />
                                <FormError name="email" />
                            </div>
                            <div className="mb-1">
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <FormInput type="password" id="password" name="password" validation={{ required: "Password is required.", minLength: { value: 8, message: "Password must be at least 8 characters." } }} />
                                <FormError name="password" />
                            </div>
                            <div className="mb-4 text-right">
                                <Link to="/forgot-password" className="text-xs text-primary">Forgot password?</Link>
                            </div>
                            <div className="mb-8">
                                <button disabled={form.formState.isSubmitting} type="submit" className="flex w-full items-center justify-center gap-2 rounded-lg bg-primary px-5 py-2.5 text-sm text-light-white-text dark:text-dark-white-text focus:ring-2 focus:ring-primary/50 focus:ring-offset-white/5">
                                    Login
                                    {form.formState.isSubmitting && <CircleNotch className="size-4 animate-spin" />}
                                    {!form.formState.isSubmitting && <ArrowRight className="size-4" />}
                                </button>
                            </div>
                            <div className="text-center">
                                <p className="text-sm">Don&apos;t have an account? <Link to="/signup" className="text-primary">Register</Link></p>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </AuthLayout>
    );
};

// const LoginPageWrapper = () => (
//   <GoogleReCaptchaProvider reCaptchaKey="6LcfS5MpAAAAALq3cIca-jWeg4sw3tPUCaUB4bUd">
//     <GoogleReCaptcha
//       className="google-recaptcha-custom-class"
//     />
//     <LoginPage />
//   </GoogleReCaptchaProvider>
// );

export default LoginPage;