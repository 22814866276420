import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import api from '../../services/apiService';
import FormSubmit from '../../components/Form/FormSubmit';
import FormInput from '../../components/Form/FormInput';
import FormLabel from '../../components/Form/FormLabel';
import FormTextarea from '../../components/Form/FormTextarea';
import FormError from '../../components/Form/FormError';
import SingleImageUploader from '../../components/SingleImageUploader';
import { useApp } from '../../contexts/AppContext';

const BranchCreatePage = () => {
    const form = useForm();
    const { selectedRestaurant } = useApp();

    const onSubmit = async (data) => {
        try {
            console.log(selectedRestaurant);

            const restaurantCreateResponse = await api.post(`restaurants/${selectedRestaurant.id}/branches`, data);

            if (restaurantCreateResponse.data.success) {
                form.reset();
            }

            console.log(restaurantCreateResponse.data);
        } catch (error) {
            console.error(error);
        }
    };

    const fields = [
        {
            section: 'General',
            fields: [
                {
                    name: 'address',
                    label: 'Branch Address',
                    type: 'text',
                    validation: { required: 'Branch Address is required' }
                },
                {
                    name: 'latitude',
                    label: 'Branch Latitude',
                    type: 'number',
                    step: 'any',
                    validation: { required: 'Branch Latitude is required' }
                },
                {
                    name: 'longitude',
                    label: 'Branch Longitude',
                    type: 'number',
                    step: 'any',
                    validation: { required: 'Branch Longitude is required' }
                }
            ]
        }
    ];

    return (
        <DefaultLayout>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="mb-8 flex flex-col gap-4 md:flex-row md:items-end md:justify-between">
                        <div>
                            <h1 className="mb-2 text-2xl font-semibold text-light-white-text dark:text-dark-white-text">
                                Shop
                            </h1>
                            <p className="text-sm">
                                Manage your shop settings.
                            </p>
                        </div>
                        <div className="flex gap-4">
                            <FormSubmit form={form} label="Create" />
                        </div>
                    </div>

                    <div className="flex flex-col gap-4">
                        {fields.map((section, index) => (
                            <div key={index} className="rounded-lg border border-white/5 bg-light-dark-bg dark:bg-dark-dark-bg">
                                <div className="border-b border-white/5 px-6 py-4">
                                    <h2 className="text-lg font-semibold text-light-white-text dark:text-dark-white-text">{section.section}</h2>
                                </div>
                                <div className="px-6 py-4">
                                    {section.fields.map((field, index) => (
                                        <div key={index} className="mb-3">
                                            <FormLabel htmlFor={field.name}>{field.label}</FormLabel>
                                            {['text', 'email', 'url', 'number', 'password'].includes(field.type) && (
                                                <FormInput id={field.name} name={field.name} type={field.type} step={field.step} validation={field.validation} />
                                            )}
                                            {field.type === 'textarea' && (
                                                <FormTextarea id={field.name} name={field.name} rows="4" validation={field.validation} />
                                            )}
                                            {field.type === 'image' && (
                                                <Controller
                                                    id={field.name}
                                                    name={field.name}
                                                    control={form.control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <SingleImageUploader
                                                            imageId={value || null}
                                                            setImageId={(ids) => onChange(ids)}
                                                        />
                                                    )}
                                                />
                                            )}
                                            <FormError name={field.name} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </form>
            </FormProvider>
        </DefaultLayout>
    );
};

export default BranchCreatePage;