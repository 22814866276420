import { useForm, FormProvider, Controller } from 'react-hook-form';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { Plus, X } from '@phosphor-icons/react';
import { useParams } from 'react-router-dom';
import DefaultLayout from '../../../layouts/DefaultLayout';
import FormLabel from '../../../components/Form/FormLabel';
import FormError from '../../../components/Form/FormError';
import FormInput from '../../../components/Form/FormInput';
import FormTextarea from '../../../components/Form/FormTextarea';
import Select from 'react-select';
import FormSubmit from '../../../components/Form/FormSubmit';
import api from '../../../services/apiService';
import { useEffect, useState } from 'react';
import SingleImageUploader from '../../../components/SingleImageUploader';
import { useApp } from '../../../contexts/AppContext';

const ItemFormPage = () => {
    const { id } = useParams();
    const { selectedRestaurant } = useApp();

    const isCreate = id === undefined;
    const isEdit = id !== undefined;

    const form = useForm({
        defaultValues: {
            name: '',
            visibility: 'public',
            addon_groups: [],
        },
    });

    const visibilities = [
        { label: 'Public', value: 'public' },
        { label: 'Hidden', value: 'hidden' },
    ];

    const addonTypes = [
        { label: 'Radio', value: 'radio' },
        { label: 'Multi', value: 'multi' },
    ];

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // if (selectedBranch) {
        //     setCategories(selectedBranch.categories.map((category) => ({
        //         label: category.name,
        //         value: category.id,
        //     })));
        // }

        if (!selectedRestaurant) return;

        api.get(`restaurants/${selectedRestaurant.id}/categories`).then((response) => {
            if (response.data.success) {
                setCategories(response.data.categories.map((category) => ({
                    label: category.name,
                    value: category.id,
                })));
            }
            else {
                console.error(response.data.message);
            }
        }).catch((error) => {
            console.error(error);
        });
    }, [selectedRestaurant]);

    //   const navigate = useNavigate();

    useEffect(() => {
        if (!isEdit) return;

        const fetchItem = async () => {
            try {
                const { data } = await api.get(`restaurants/${selectedRestaurant.id}/items/${id}`);

                // const allowedFields = ['name', 'visibility'];

                // for (const field of allowedFields) {
                //     let value = data[field];
                //     form.setValue(field, value);
                // }

                for (const field of Object.keys(data.item)) {
                    let value = data.item[field];
                    form.setValue(field, value);
                }
            } catch (error) {
                console.error(error);
                // toast.error(`Failed to load group data (${error.response?.data?.message || error.message}).`);
                // navigate('/groups');
            }
        };

        fetchItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (data) => {
        // if (isEdit) {
        //   try {
        //     await api.put(`shops/${store.shop.id}/groups/${id}/update`, data);
        //     toast.success('Group updated successfully.');
        //     navigate('/groups');
        //   } catch (error) {
        //     console.error(error);
        //     toast.error(`Failed to update group (${error.response?.data?.message || error.message}).`);
        //     toast.error(error.response?.data?.message || error.message);
        //   }
        // } else {
        //   try {
        //     await api.post(`shops/${store.shop.id}/groups`, data);
        //     toast.success('Group created successfully.');
        //     navigate('/groups');
        //   } catch (error) {
        //     console.error(error);
        //     toast.error(`Failed to create group (${error.response?.data?.message || error.message}).`);
        //   }
        // }

        if (isCreate) {
            try {
                const createResponse = await api.post(`restaurants/${selectedRestaurant.id}/items`, data);

                if (createResponse.data.success) {
                    console.log('Item created successfully.');
                }
                else {
                    console.log(createResponse.data.message);
                }
            } catch (error) {
                console.error(error);
                console.log(error.response?.data?.message || error.message);
            }
        } else {
            try {
                const updateResponse = await api.put(`restaurants/${selectedRestaurant.id}/items/${id}`, data);

                if (updateResponse.data.success) {
                    console.log('Item updated successfully.');
                }
                else {
                    console.log(updateResponse.data.message);
                }
            } catch (error) {
                console.error(error);
                console.log(error.response?.data?.message || error.message);
            }
        }

        console.log(data);
    };

    const addAddon = (addonGroupIdx) => {
        let addon_groups = form.watch('addon_groups');

        addon_groups[addonGroupIdx].values.push({
            name: '',
            name_ar: '',
            price: ''
        });

        form.setValue('addon_groups', addon_groups);
    };

    const removeAddon = (addonGroupIdx, index) => {
        let addon_groups = form.watch('addon_groups');
        addon_groups[addonGroupIdx].values.splice(index, 1);
        form.setValue('addon_groups', addon_groups);
    };

    const addAddonGroup = () => {
        let addon_groups = form.watch('addon_groups');

        addon_groups.push({
            name: '',
            name_ar: '',
            type: 'radio',
            values: []
        });

        form.setValue('addon_groups', addon_groups);
    };

    const removeAddonGroup = (index) => {
        let addon_groups = form.watch('addon_groups');
        addon_groups.splice(index, 1);
        form.setValue('addon_groups', addon_groups);
    };

    return (
        <DefaultLayout>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="mb-8 flex flex-col gap-4 md:flex-row md:items-end md:justify-between">
                        <div>
                            <h1 className="mb-2 text-2xl font-semibold text-white">
                                {isEdit ? 'Edit' : 'Create'} Item
                            </h1>
                            <p className="text-sm">
                                {isEdit ? 'Edit the item details.' : 'Create a new item for your menu.'}
                            </p>
                        </div>
                        <div className="flex gap-4">
                            <FormSubmit form={form} label={isEdit ? 'Save' : 'Create'} />
                            <button type="button" className="inline-flex min-w-32 items-center justify-center gap-2 rounded-lg bg-light-dark-bg dark:bg-dark-dark-bg px-5 py-2.5 text-sm text-white shadow-md">
                                <X className="size-[1.125rem]" />
                                Cancel
                            </button>
                        </div>
                    </div>

                    <div className="rounded-lg border border-white/5 bg-light-dark-bg dark:bg-dark-dark-bg px-6 py-4">
                        <div className="mb-3">
                            <Controller
                                name={"restaurant_items_category_id"}
                                control={form.control}
                                rules={{ required: "Category is required." }}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        options={categories}
                                        onChange={(value) => onChange(value.value)}
                                        value={value ? categories.find((option) => option.value === value) : null}
                                        className="react-dark-select-container"
                                        classNamePrefix="react-dark-select"
                                    />
                                )}
                            />
                            <FormError name="restaurant_items_category_id" />
                        </div>
                        <div className="mb-3">
                            <FormLabel htmlFor="name">Name</FormLabel>
                            <FormInput type="text" id="name" name="name" validation={{ required: "Name is required." }} />
                            <FormError name="name" />
                        </div>
                        <div className="mb-3">
                            <FormLabel htmlFor="name_ar">Name ( Arabic )</FormLabel>
                            <FormInput type="text" id="name_ar" name="name_ar" validation={{ required: "Arabic Name is required." }} />
                            <FormError name="name_ar" />
                        </div>
                        <div className="mb-3">
                            <FormLabel>Image</FormLabel>
                            <Controller
                                id="image_id"
                                name="image_id"
                                control={form.control}
                                render={({ field: { onChange, value } }) => (
                                    <SingleImageUploader
                                        imageId={value || null}
                                        setImageId={(ids) => onChange(ids)}
                                        maxImages={1} />
                                )}
                            />
                        </div>
                        <div className="mb-3">
                            <FormLabel htmlFor="base_price">Base Price</FormLabel>
                            <FormInput type="number" id="base_price" step="0.01" name="base_price" placeholder="1.00" validation={{ required: "Base Price is required." }} />
                            <FormError name="base_price" />
                        </div>
                        <div className="mb-3">
                            <FormLabel htmlFor="base_calories">Base Calories</FormLabel>
                            <FormInput type="number" id="base_calories" step="1" name="base_calories" required={false} placeholder="1" />
                            <FormError name="base_calories" />
                        </div>
                        <div className="mb-3">
                            <FormLabel htmlFor="visiblity">Visibility</FormLabel>
                            <Controller
                                name="visibility"
                                control={form.control}
                                rules={{ required: "Visibility is required." }}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        options={visibilities}
                                        onChange={(value) => onChange(value.value)}
                                        value={value ? visibilities.find((option) => option.value === value) : null}
                                        className="react-dark-select-container"
                                        classNamePrefix="react-dark-select"
                                    />
                                )}
                            />
                            <FormError name="visibility" />
                        </div>
                    </div>

                    {form.watch('addon_groups').map((_, addonGroupIdx) => (
                        <div className="mt-4 rounded-lg border border-white/5 bg-light-dark-bg dark:bg-dark-dark-bg px-6 py-4">
                            <div className="divide-y divide-white/5">
                                <div className="flex items-center gap-4">
                                    <div className="w-[10%]">
                                        <FormInput type="text" placeholder="Group Name" name={`addon_groups[${addonGroupIdx}].name`} validation={{ required: "Name is required." }} />
                                        <FormError name={`addon_groups[${addonGroupIdx}].name`} />
                                    </div>
                                    <div className="w-[10%]">
                                        <FormInput type="text" placeholder="Group Name ( Arabic )" name={`addon_groups[${addonGroupIdx}].name_ar`} validation={{ required: "Arabic Name is required." }} />
                                        <FormError name={`addon_groups[${addonGroupIdx}].name_ar`} />
                                    </div>
                                    <div className="w-[8%]">
                                        <Controller
                                            name={`addon_groups[${addonGroupIdx}].type`}
                                            control={form.control}
                                            rules={{ required: "Type is required." }}
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    options={addonTypes}
                                                    onChange={(value) => onChange(value.value)}
                                                    value={value ? addonTypes.find((option) => option.value === value) : null}
                                                    className="react-dark-select-container"
                                                    classNamePrefix="react-dark-select"
                                                />
                                            )}
                                        />
                                        <FormError name="type" />
                                    </div>
                                    <button type="button" onClick={() => removeAddonGroup(addonGroupIdx)} className="inline-flex items-center justify-center gap-2 rounded-lg  border border-white/5 bg-light-dark-bg-active dark:bg-dark-dark-bg-active px-4 py-2.5 text-sm text-light-gray-text dark:text-dark-gray-text">
                                        <X className="size-[1.125rem]" />
                                        Remove
                                    </button>
                                </div>

                                <div className="mt-4 rounded-lg border border-white/5 bg-light-darker-bg dark:bg-dark-darker-bg dark:bg-dark-darker-bg px-6 py-4">
                                    <div className="mb-3">
                                        <FormLabel>Addon Values</FormLabel>
                                    </div>
                                    <div className="divide-y divide-white/5">
                                        {_.values.map((_, addonIdx) => (
                                            <div key={addonIdx} className="mb-4 pt-4">
                                                <div className="flex items-center gap-4">
                                                    <div className="w-[10%]">
                                                        <FormInput type="text" placeholder="Addon Name" name={`addon_groups[${addonGroupIdx}].values[${addonIdx}].name`} validation={{ required: "Name is required." }} />
                                                        <FormError name={`addon_groups[${addonGroupIdx}].values[${addonIdx}].name`} />
                                                    </div>
                                                    <div className="w-[10%]">
                                                        <FormInput type="text" placeholder="Addon Name ( Arabic )" name={`addon_groups[${addonGroupIdx}].values[${addonIdx}].name_ar`} validation={{ required: "Arabic Name is required." }} />
                                                        <FormError name={`addon_groups[${addonGroupIdx}].values[${addonIdx}].name_ar`} />
                                                    </div>
                                                    <div className="w-[10%]">
                                                        <FormInput type="number" step="0.01" placeholder="Addon Price" name={`addon_groups[${addonGroupIdx}].values[${addonIdx}].price`} validation={{ required: "Price is required." }} />
                                                        <FormError name={`addon_groups[${addonGroupIdx}].values[${addonIdx}].price`} />
                                                    </div>
                                                    <div className="w-[10%]">
                                                        <FormInput type="number" step="1" placeholder="Addon Calories" name={`addon_groups[${addonGroupIdx}].values[${addonIdx}].calories`} validation={{ required: "Calories is required." }} />
                                                        <FormError name={`addon_groups[${addonGroupIdx}].values[${addonIdx}].calories`} />
                                                    </div>
                                                    <button type="button" onClick={() => removeAddon(addonGroupIdx, addonIdx)} className="inline-flex items-center justify-center gap-2 rounded-lg  border border-white/5 bg-light-dark-bg-active dark:bg-dark-dark-bg-active px-4 py-2.5 text-sm text-light-gray-text dark:text-dark-gray-text">
                                                        <X className="size-[1.125rem]" />
                                                        Remove
                                                    </button>
                                                </div>
                                                <div>
                                                    {form.watch('deliverables_type') === 'dynamic' && (
                                                        <div className="mt-3">
                                                            <FormInput type="url" placeholder="http://example.com" name={`addon_groups[${addonGroupIdx}].values[${addonIdx}].deliverables`} />
                                                        </div>
                                                    )}
                                                    {form.watch('deliverables_type') === 'serials' && (
                                                        <div className="mt-3">
                                                            <FormTextarea rows="3" placeholder="1234-5678-9101-1121&#10;2234-5678-9101-1122" name={`addon_groups[${addonGroupIdx}].values[${addonIdx}].deliverables`} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}

                                        <button onClick={() => addAddon(addonGroupIdx)} type="button" className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-3 py-2 text-xs text-white shadow-md">
                                            <Plus className="size-4" />
                                            <span>Add Addon</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <button onClick={addAddonGroup} type="button" className="mt-4 inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-3 py-2 text-xs text-white shadow-md">
                        <Plus className="size-4" />
                        <span>Add Addon Group</span>
                    </button>

                    {/* TODO: Products list */}
                </form>
            </FormProvider>
        </DefaultLayout >
    );
};

export default ItemFormPage;