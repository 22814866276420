import axios from 'axios';

// Import necessary modules

// API endpoints
const registerEndpoint = 'https://api.seen-delivery.com/api/v1/user/register';
const loginEndpoint = 'https://api.seen-delivery.com/api/v1/user/login';
const userDataEndpoint = 'https://api.seen-delivery.com/api/v1/user/';

// Function to register a user
async function registerUser(email, password) {
    try {
        // Send OTP to the phone number
        const response = await axios.post(registerEndpoint, {
            email: email,
            password: password,
        });

        // Handle the response and return the result
        return response.data;
    } catch (error) {
        // Handle any errors
        console.error('Error registering user:', error);
        throw error;
    }
}

// Function to login a user
export const loginUser = async (email, password) => {
    try {
        // Send the OTP to receive a token
        const response = await axios.post(loginEndpoint, {
            email: email,
            password: password,
        });

        // Handle the response and return the token
        return response.data;
    } catch (error) {
        // Handle any errors
        console.error('Error logging in user:', error);
        throw error;
    }
}

export const getUserData = async (userToken) => {
    try {
        // Assuming there's an endpoint for getting user data
        const response = await axios.get(userDataEndpoint, {
            headers: {
                Authorization: `Bearer ${userToken}` // Assuming token-based authentication
            }
        });

        // Handle the response and return the user data
        return response.data;
    } catch (error) {
        // Handle any errors
        console.error('Error getting user data:', error);
        throw error;
    }
}

// Assign the exported functions to a variable
const authService = {
    registerUser,
    loginUser,
    getUserData
};

// Export the variable as the default module
export default authService;
