import { useFormContext } from 'react-hook-form';

const FormError = ({ name, ...rest}) => {
  const { formState } = useFormContext();

  return (
    (formState.errors?.[name] && (
      <p className="mt-1 text-xs text-red-500" {...rest}>{formState.errors[name].message}</p>
    ))
  );
};

export default FormError;
